import Vue from 'vue'
import VueRouter from 'vue-router'

import articleDetails from '@/views/articleDetails/index.vue'
import Details from '@/views/courseDetails/details.vue'
import CourseDetails from '@/views/courseDetails/index.vue'
import LoginVue from '@/views/courseDetails/login.vue'
import Payment from '@/views/courseDetails/payment.vue'
import PlaybackDetails from '@/views/playbackDetails/index.vue'

Vue.use(VueRouter)


const router = new VueRouter({
  routes: [
    {path:'/', redirect: 'main/homePage' },//登录 默认打开页
    {
      path: '/main',
      name: 'main',
      component: () => import('@/views/main.vue'),
      children: [
        {
          path: 'homePage',
          component: () => import('@/views/homePage.vue'),
          meta: {
            title: '首页',
            //isAuth: true, 是否开启身份验证
            //keepAlive: true,//让它缓存
          }
        },
        {
        path: 'curriculumCenter',
        component: () => import('@/views/curriculumCenter.vue'),
          meta: {
            title: '课程中心',
          }
        },
        {
        path: 'system',
        component: () => import('@/views/system.vue'),
          meta: {
            title: '课程体系',
          }
        }, 
        {
          path: 'teachingTeam',
          component: () => import('@/views/teachingTeam.vue'),
          meta: {
            title: '教学团队',
          }
        },
        {
          path: 'platform',
          component: () => import('@/views/platform.vue'),
          meta: {
            title: '教学平台',
          }
        },
        {
          path: 'searchArticle',
          component: () => import('@/views/searchArticle.vue'),
          meta: {
            title: '文章资讯',
          }
        },
        {
          path: 'aboutUs',
          component: () => import('@/views/aboutUs.vue'),
          meta: {
            title: '关于我们',
          }
        },
        {
          path: '/search-result/:keyword',
          name: 'searchResult',
          component: () => import('@/views/searchResult.vue'),
        },
      
      ]
    },
    { path: '/articleDetails', component: articleDetails },//文章详情页
    { path: '/details', component: Details },//视频详情页
    { path: '/courseDetails', component: CourseDetails},//课程详情页
    { path: '/login', component: LoginVue },
    { path: '/payment', component: Payment },
    { path: '/playbackDetails', component: PlaybackDetails },
   // { path: '*', component: NotFind },404
  ],
  scrollBehavior(to, from, savedPosition) {       //添加该方法
    return { x: 0, y: 0 }
  }
})


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !store.state.isLoggedIn) {
    next({
      path: '/login',
      query: { redirect: to.fullPath } // 将来源路径保存在query参数redirect中
    });
  } else {
    next();
  }
});

export default router
