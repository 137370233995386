<template>
  <div>
    <!-- 跳转视频播放详情页内容 -->
    <div class="course-detail-container">
      <div class="left-side">
        <!-- 视频播放区域 -->
        <div class="article-detail-page">
          <nav class="nav">
            <span class="back" @click="$router.back()">&lt;</span>课程标题
          </nav>
           <div id="app">
             <github-badge slug="slacrey/vue-aliplayer" />
             <div v-if="aplayer.vid && aplayer.playauth">
                <ali-player @play="played" :autoplay="true" :source="aplayer.source"
                         :vid="aplayer.vid" ref="player" :playauth="aplayer.playauth" 
                          style="width: 100%; height: 600px;"></ali-player>
              </div>
           </div>
           <div style="margin-top:20px;">
              <button @click="play">播放</button>
              <button @click="pause">暂停</button>
           </div> 
         
        </div>
        
      </div>
      <div class="right-side">
        <div class="article-detail-page">
          <nav class="title">目录</nav>
        </div>
        <!-- 可折叠树形菜单 -->
        <el-tree
          :data="menuData"
          node-key="id"
          default-expand-all
          accordion
          highlight-current
          @node-click="handleNodeClick"
        ></el-tree>
      </div>
    </div>
  </div>
</template>

<script>
// 阿里云
import VueAliplayer from "./VueAliplayer.vue";
import GitHubBadge from "vue-github-badge";

export default {
  components: {
    "ali-player": VueAliplayer,
    "github-badge": GitHubBadge
  },
  data() {
    return {
      aplayer: {
        source: "",
        vid: "",
        playauth:"",
        videoHeight: "1000px",
        videoWidth: "800px",
      },
      key: null,
      receivedCatalogue: "",
      title: "",
      menuData: [
        // 示例树形菜单数据，实际应从后端获取
        // {
        //   id: 1,
        //   label: '章节1',
        //   children: []
        // },
        // 更多章节...
      ],
       
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.receivedCatalogue = JSON.parse(vm.$route.query.catalogue);
      const transformedData = vm.receivedCatalogue.map(item => ({
        label: `一级 ${item.key}`
      }));
      console.log("1111", transformedData);
      vm.menuData = transformedData;
    });
  },
  created() {
    this.key = this.$route.query.id;
  },
  mounted() {
    this.getVideoSource().then(() => {
      // this.aplayer.source = videoInfo.url;
      // 其他初始化或更新播放器的操作
    }).catch((error) => {
      console.error('Failed to fetch video source:', error);
    });
  },
  computed: {
    currentCourse() {
      return this.$store.getters.getCourseId;
    }
    //   previousId() {
    //   return this.$store.state.previousRoute.params.id; // 如果id在params里
    // },
  },
  methods: {
    played() {
      console.log("play callback");
    },
    play() {
      const player = this.$refs.player.instance;
      player && player.play();
    },
    pause() {
      const player = this.$refs.player.instance;
      player && player.pause();
    },
    // 视频id
    async getVideoSource() {
      // 假设有个 API 获取 vid 和鉴权后的播放地址

      const key = this.key;
      try {
        const res = await this.$axios.get(`m1/video/play/${key}/`);
        console.log(res);
        if (res.code === 200) {
          // 处理成功响应，例如：获取并设置视频源
           this.aplayer.playauth = res.data.playAuth;
           this.aplayer.vid = res.data.vid;
           this.aplayer.source = res.data.videoUrl;
           console.log(this.aplayer,'this.aplayer')
        }
      } catch (error) {
        console.error("An error occurred while fetching video source:", error);
      }
    },
  
    handleNodeClick(node) {
      // 处理节点点击事件，例如：跳转到对应课程内容等
      console.log("Clicked node:", node);
    }
  }
};
</script>

<style scoped lang="scss">

.article-detail-page {
  position: relative;
  box-sizing: border-box;
  .nav {
    height: 55px;
    color: #ededee;
    line-height: 55px;
    font-size: 18px;
    text-align: center;
    background-color: #272836;
    .back {
      font-size: 18px;
      color: #666;
      position: absolute;
      left: 10px;
      top: 0;
      transform: scale(1, 1.5);
    }
  }
  .title {
    height: 55px;
    color: #ededee;
    line-height: 55px;
    font-size: 18px;
    text-align: left;
  }
}

.course-detail-container {
  display: flex;
  background-color: #272836;
}

.left-side {
  width: 80%;
  height: 100vh;
  padding: 0 20px;
}
.right-side {
  width: 20%;
  height: 100vh;
  padding: 0 0 0 10px;
  box-shadow: -5px 0 5px rgba(0, 0, 0, 0.3);
  background-color: #222332;
}
::v-deep .el-tree {
  color: #ededee;
  background-color: #222332;
}
::v-deep .el-tree-node > .el-tree-node__children {
  background-color: #2c2d3d;
  color: #9091a2;
}
::v-deep .el-tree-node__content:hover {
  background-color: #424347;
}
::v-deep .el-tree-node__content:click {
  background-color: #424347;
}
::v-deep
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #424347;
}
// #2c2d3d
.video-player {
  height: calc(100% - 55px);
  overflow: hidden;
}
</style>