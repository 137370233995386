import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './utils/request'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import ReLoginModal from '@/components/ReLoginModal.vue';


Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$axios = axios


// 请求拦截器 
axios.interceptors.request.use(config => {
  // alert(store.state.UserID)
  if (store.state.token) {
    config.headers.Authorization = `Bearer${store.state.token}`,
    config.headers.userId = `${store.state.UserID}`
  }
  return config
})

// 响应拦截器 
axios.interceptors.response.use(resp => {
  return resp.data
}, error => {
  switch (error.response.status) {
    case 418:// token不存在 
      store.dispatch('logout'); // 调用logout action
      router.replace({
        path: "/login",
      });
      break;
    case 419: // token无效 
      store.dispatch('logout'); 
      router.replace({
        path: "/login",
      });
      break;
    case 420:// 账号已在其他设备登录
      // alert("123420")     
      store.dispatch('logout'); //清空账号
      // 弹出重新登录提示框
      // const reLoginModal = new Vue({
      //   render: (h) => h(ReLoginModal, { on: { close: () => router.replace({ path: "/login" }) } }),
      // }).$mount();
      // document.body.appendChild(reLoginModal.$el);
      // reLoginModal.$emit('show');

      router.replace({
        path: "/login",
      });
      break;
    case 421://用户未登录
      store.dispatch('logout');
      router.replace({
        path: "/login",
      });
      break;
    default:
      break;
  }
  return Promise.reject(error)
})

new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')
