<template>
  <div>
    <!-- 支付 -->
    <!-- 微信二维码 -->
    
     <div class="centent">
       <p>请扫描微信二维码支付</p>
       <div class="codeImg">
          <vue-qr
            ref="qrCode"
            :text="qrCodeUrl"
          >
          </vue-qr>
        </div>
     </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr'

export default {
  name: "",
  components:{VueQr},
  data() {
    return {
      qrCodeUrl: '', // 存放生成的二维码url
    };
  },
  mounted() {
    this.creatQrCode()
  },

  methods: {
    async creatQrCode() {
      const randomStr = Math.random().toString(36).substring(2);
      const res = await this.$axios
        .post(`/m1/order/s/${this.$route.query.id}?random=${randomStr}`)
        .then(res => {
          console.log(res);
          this.qrCodeUrl = res.msg;
        });
    },
    
  }
};
</script>

<style lang="scss" scoped>
.centent{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  p{
    font-size: 20px;
    margin-bottom: 20px;
  }
  .codeImg{
    width: 200px;
    height: 200px;
  }
}
</style>