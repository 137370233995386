import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    isLoggedIn: false, // 用户登录状态，默认为未登录
    token: null,
    UserID: null, // 用户信息id
  },
  mutations: {
    // 用户登录状态
    setLoginStatus(state, value) {
      state.isLoggedIn = value;
    },
    setToken(state, token) {
      state.token = token;
      // console.log(token,'tokentokentokenSS')
    },
    setUserID(state, id) {
      state.UserID = id;
      // console.log(state.UserID)
    },
    clearToken(state) {
      state.token = null;
    },
    clearUserId(state) {
      state.UserID = null;
    },
  },
  actions: {
    logout({ commit }) {
      // 清空 token 和 userId
      commit('clearToken');
      commit('clearUserId');
      commit('setLoginStatus', false); // 设置 isLoggedIn 为 false
    },
  },
  getters: {
      // 获取 token 的 getter
      getToken: state => state.token,
      getUserId: state => state.UserID,
      isLoggedIn: state => state.isLoggedIn,
  },
  /* vuex数据持久化配置 */
  plugins: [
    createPersistedState({
     // storage: window.localStorage, // 存储的key的key值
      // 全部持久化
      reducer (state) {
        // 要存储的数据：采用es6扩展运算符的方式存储了state中所有的数据
        return { ...state }
      }
      // 部分持久化 只持久化存储user模块的状态
      // paths: ['UserID','isLoggedIn','token']
    })
  ]
})

export default store

