<template>
  <div class="article-detail-page">
    
        <!-- 跳转课程详情页内容 -->
        <div >
          <div class="box SellLessons">
            <el-row :gutter="20">
              <el-col :span="8">
                <div class="grid-content bg-purple">
                  <img src="https://oss-xcp-backend.huatu.com/snj2/f9bca202309010854327712.png" alt="">
                </div>
              </el-col>
              <el-col :span="16">
                <div class="grid-content bg-purple purchaseCourse">
                  <h1>{{list.title}}</h1>
                  <p class="teamName">
                    <i class="el-icon-price-tag"></i>
                    <span>标签：{{list.type}}</span>
                  </p>
                  <div class="price">
                    <i>￥</i>
                      <span>{{list.price}}</span>
                      <i>.00</i>
                    </div>
                    <div class="freight">咨询：<span>13811861337 (微信同号)</span></div>
                    <el-button type="text" @click="open()">购买</el-button>
                  </div>
              </el-col>
            </el-row>
          </div>
          <div class="box">
            <el-tabs tab-position="left"  style="height: 400px;">
              <el-tab-pane label="课程介绍">
                <p class="courseTitle">课程介绍</p>
                <div v-html="list.description"> {{list.description}}</div>

                <p class="courseTitle">课程目录</p>
                <div v-for="(item,index) in catalogue" :key="item.id" class="catalogue">
                   <i class="cricre">●</i>
                     {{index + 1}}
                    <span @click="goToCourseDetails(item.key)">{{item.value}}</span>
                </div> 
                
                <p class="courseTitle">师资介绍</p>
                <div v-for="item in teacher" :key="item.id" class="introduce">
                  <div class="teName"> 
                    <img :src="item.image" alt="">
                    <span>{{item.name}}</span>
                  </div>
                  <div>
                    {{item.introduce}}
                  </div>
                </div>

                <p class="courseTitle">常见问题</p>

                <div class="article">
                  常见问题
                </div>
              </el-tab-pane>
              <el-tab-pane label="课程目录">
                <div v-for="(item,index) in catalogue" :key="item.id" class="catalogue">
                   <i class="cricre">●</i>
                     {{index + 1}}
                    <span @click="goToCourseDetails(item.key)">{{item.value}}</span>
                </div>
              </el-tab-pane>
              <el-tab-pane label="师资介绍">
                <div v-for="item in teacher" :key="item.id" class="introduce">
                  <div class="teName"> 
                    <img :src="item.image" alt="">
                    <span>{{item.name}}</span>
                  </div>
                  <div>
                    {{item.introduce}}
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="常见问题">
                <div class="article">
                  常见问题
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
          
        </div>
  </div>
</template>

<script>
export default {
  name: "ArticleDetailPage",
  data() {
    return {
      list:[],
      catalogue:'',
      teacher:'',
      id:""
    };
  },
  mounted(){
    this.getDetail();
  },
  methods:{
     async getDetail(){
    // 课程详情页
          const res = await this.$axios.get(`m1/course/v/${this.$route.query.id}`)
          this.list=res.data;
          this.id = res.data.id
          // 课程目录
          const m = res.data.dir
          // console.log(m);
          this.catalogue = Object.keys(m).map(item => ({key:item, value:m[item]}));
          // 遍历后
          // console.log(this.catalogue); 
          this.teacher = res.data.t
      },
      // 跳转到播放页
      goToCourseDetails(key) {
          if (this.$store.state.isLoggedIn) {
            this.$router.push({
              path: '/Details',
              query: { id: key, catalogue: JSON.stringify(this.catalogue) }
            });
          } else {
            this.$router.push('/login');
          }
        },
      open() {
        if (this.$store.state.isLoggedIn) {
          // 用户已登录，执行购买逻辑
          this.$router.push(`/payment?id=${this.id}`)
        } else {
           this.$router.push(`/login`);
        }
      },
  },
};
</script>

<style lang="scss" scoped>
.article-detail-page {
    width: 1280px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
  
  .body {
    padding: 0 15px;
  }
}

// 跳转课程详情页
.purchaseCourse{
  padding: 10px;
  h1{
    margin: 5px 0;
    color: #333;
    font-weight: 700;
    font-size: 30px;
    line-height: 1.5;
    overflow: hidden;
  }
  .teamName{
    font-size: 13px;
    padding-left: 8px;
    line-height: 30px;
    color: #9E9E9E;
    i{
      transform: rotate(-45deg);
    }
    span{
      padding: 0 5px;
    }
  }
  .price{
    color: #f60;
    font-size: 25px;
    margin: 20px 0;
  }
  .freight{
    margin-bottom: 25px;
    // background-color: #f8f8f8;
    color: #333;
    font-size: 14px;
    padding: 15px 0;
    span{
      padding: 0 20px 0 10px;
    }
  }
  button{
    min-width: 160px;
    padding: 0 8px;
    height: 50px;
    background: #FF9800;
    color: #fff;
    text-align: center;
    font-size: 22px;
    line-height: 50px;
    margin: 0 3px;
    overflow: hidden;
    border: none;
}
}
// 
::v-deep .el-tabs{
   color: #777;
}
::v-deep .el-tabs__item{
   color: #777;
   font-size: 16px;
}
::v-deep .el-tabs__item:hover{
   color: #2a2a2a;
}

.box {
  width: 100%;
  height: auto;
  background-color: #fff;;
  padding: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
  line-height: 24px;
  img{
    width: 100%;
    height: 100%;
  }
  .courseTitle{
    text-align: center;
    margin: 30px 0 ;
    color: #333;
    font-size: 20px;
    font-weight: 600;
  }
}
::v-deep .el-tab-pane{
  padding: 0 20px;
}
// ::v-deep .el-tabs__item {
//   font-size: 20px;
// }
::v-deep .el-tabs__content {
  overflow: visible;
}
::v-deep .el-tabs__content{
  height: 400px;
  overflow-y: auto; 
}
::v-deep .el-tabs__content::-webkit-scrollbar {
  width: 8px; /* 定义滚动条宽度 */
}
::v-deep .el-tabs__content::-webkit-scrollbar-thumb {
  background-color: transparent; /* 透明背景 */
  border-radius: 8px; /* 圆角 */
}

// 
.catalogue{
  height: 38px;
  line-height: 38px;
    font-size: 14px;
  border-bottom: 1px solid #E8E8E9;
  .cricre{
   margin-right: 5px;
    color: #c2c2ca;
    display: inline;
  }
  span{
    color: #777777;
  }
}
.introduce{
  width: 100%;
  height: auto;
  padding: 30px 0;
  border-bottom: 1px solid #E8E8E9;
  .teName{
    margin-bottom: 15px;
   img{
    width: 70px;
    height: 70px;
    margin-right: 20px;
    border-radius: 50%;
  }
  span{
    font-size: 18px;
    display: inline-block;
    vertical-align: top;
    color: #333;
    line-height: 70px;
  } 
  }
  
}
::v-deep .el-button{
  border: none;
}
::v-deep .el-tooltip__popper{
  margin: 0;
}
</style>
