<template>
  <div class="article-detail-page">
    <!-- <nav class="nav"><span class="back" @click="$router.back()">&lt;</span> 文章详情</nav> -->
    <!-- 文章页面 -->
    <div class="box">
      <h1>{{list.title}}</h1>
	    <div v-html="html"  class="md-content"></div>
    </div>
  </div>
</template>

<script>
import {marked} from 'marked';
export default {
  name: "ArticleDetailPage",
  computed:{},
  data() {
    return {
        list:'',
        html:'',
    };
  },
  created(){
      this.getDetail();
  },
  methods:{
     async getDetail(){
          const res = await this.$axios.get(`m1/article/${this.$route.query.id}`)
          // console.log(res);
          this.list=res.data;
          this.html = marked(res.data.content);
      }
  },
};
</script>

<style lang="scss" scoped>

.box {
  width: 100%;
    min-height: 800px;
  background-color: #fff;;
  padding: 20px 40px 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 20px;
  line-height: 24px;
  color: #191b1f;
    box-sizing: border-box;
    h1{
     font-size: 32px;
     font-weight: 700;
     line-height: 45px;
     margin-bottom: 20px;
     margin-top: 10px;
   }
  h3{
  word-wrap: break-word;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 21px;
  }
}


  
</style>
