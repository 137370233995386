
        <!-- 直播回放 -->
<template>
  <div class="article-detail-page">
    <nav class="nav">
      <span class="back" @click="$router.back()">&lt;</span> 直播详情
    </nav>

    <!-- 跳转直播课详情页内容 -->
    <div class="content">
      <h2>直播名称</h2>
      
      <div class="video">
        <!-- 直播回放视频 -->
      </div>
      <div class="bottom">
        <el-popover placement="top-start" width="100" trigger="hover">
          <img class="icon" :src="'http://123.57.216.146/' + 'wechat_teacher.png'" alt />
          <el-button slot="reference">关注公众号</el-button>
        </el-popover>
        <el-popover placement="top-start" width="100" trigger="hover">
          <img class="icon" :src="'http://123.57.216.146/' + 'wechat_public.jpg'" alt />
          <el-button slot="reference">
            <i class="el-icon-chat-dot-round"></i> 咨询老师
          </el-button>
        </el-popover>
      </div>
    </div>
  </div>
</template>
<script>
import EasyPlayer from '@easydarwin/easyplayer'
export default {
  components: { EasyPlayer },
  name: "ArticleDetailPage",
  data() {
    return {
      list: []
    };
  },
  created() {
    //   this.getDetail();
  },
  methods: {
    //  async getDetail(){
    //       const res = await this.$axios.get(`m1/system/replayl/${this.$route.query.id}`)
    //       console.log(res);
    //       this.list=res.data.data;
    //       console.log(this.list);
    //   }
  }
};
</script>

<style lang="scss" scoped>
.article-detail-page {
  width: 1280px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  .nav {
    height: 55px;
    border-bottom: 2px solid #f4f4f4;
    line-height: 55px;
    font-size: 18px;
    text-align: center;
    background-color: #fff;
    margin: 0 -1000px;

    .back {
      font-size: 18px;
      color: #666;
      position: absolute;
      left: 10px;
      top: 0;
      transform: scale(1, 1.5);
    }
  }

  .body {
    padding: 0 15px;
  }
}
.content {
  height: auto;
  margin-bottom: 25px;
  background-color: #333;
  color: #999;
  font-size: 14px;
  padding: 15px 0;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .video {
    padding: 0 20px;
    height: 500px;
    box-sizing: border-box;
    video {
      width: auto;
      height: 100%;
    }
  }
  .bottom {
    span {
      margin: 0 20px;
    }
    button {
      color: #999;
      background: #333;
      border: none;
    }
  }
}

.icon {
  width: 150px;
  height: 150px;
}
</style>
