
<template>
  <div class="app">
    <keep-alive>
      <router-view :key="$route.fullPath"/>
    </keep-alive>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss">
*{
  margin: 0;
  padding: 0;
}
.breadth{
  padding: 20px 40px 0px 40px;
  // width:1280px;
  //  position: relative;
  //  left: 50%;
  //  transform: translateX(-50%);
}
// md渲染 css样式
.md-content {
    h1{
     font-size: 32px;
     font-weight: 700;
     line-height: 45px;
   }
   h1,h2,h3,h4,h5,h6{
     margin: 10px 0 20px 0;
     color: #333;
   }
   /* Style paragraphs */ 
   
    p {
     margin-bottom: 20px;
     img{
      //  width: 100%;
       height: auto;
     }
   }
   /* Style links */
   a {
     color: #007BFF;
     text-decoration: none;
   }
   
   a:hover {
     text-decoration: underline;
   }
   
}

html{
  color: #333;
    font-size: 16px;
    font-weight: 500;
    font-family: PingFangSC-Regular,PingFang SC;
    background: #f4f4f4;
}
li{
  list-style: none;
}
a{
  text-decoration: none;
  color: #333;
}
i{
  font-style: normal;
}
</style>
