<template>
  <div class="main">
    <div class="bg">
      <div class="logo">
        <img src="http://123.57.216.146/logo_2.png" alt />
      </div>
      <p class="text">登&nbsp;陆</p>
      <p>
        <input type="text" v-model="username" placeholder="请输入手机号" />
      </p>
      <p>
        <input  v-model="password"  type="password" placeholder="请输入登录密码" />
      </p>
      <p class="btn">
        <el-button :plain="true" class="blue" @click="login">登&emsp;陆</el-button>
      </p>
      <p class="btn botbtn">
        <button @click="signIn()">普通用户注册</button>
        <span>|</span>
        <button @click="unexpected()">校区注册</button>
      </p>
    </div>
    <!-- 注册弹窗 -->
    <div>
      <el-dialog
        model-value="editShow"
        :visible.sync="editShow"
        :modal-append-to-body="false"
        title="用户注册"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="手机号:" prop="phone">
            <el-input v-model="ruleForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="密码:" prop="checkPass">
            <el-input v-model="ruleForm.checkPass"></el-input>
          </el-form-item>
          <el-form-item label="输入验证码:" prop="code">
            <el-input v-model="ruleForm.code" class="code"></el-input>
            <el-button :plain="true" v-if="isSend" @click="openCode()">获取验证码</el-button>
            <el-button  v-if="!isSend">{{sendmsg}}</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="cancel()">取消</el-button>
            <el-button :plain="true" type="primary" @click="add()">立即创建</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <!-- 忘记密码 -->
    <div>
      <el-dialog
        model-value="passShow"
        :visible.sync="passShow"
        :modal-append-to-body="false"
        title="校区注册 - 扫码咨询客服"
      >
        <el-form ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <div class="block">
            <el-image :src="url"></el-image>
          </div>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  name: "loginVue",
  data() {
    return {
      username: "",
      password: "",
      editShow: false,
      passShow: false,
      sendmsg: "发送验证码",
      isSend: true,
      ruleForm: {
        phone: "",
        checkPass: "",
        code: ""
      },
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            validator: function(rule, value, callback) {
              if (/^1[345678]\d{9}$/.test(value) == false) {
                callback(new Error("手机号格式错误"));
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ],
        checkPass: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { min: 6, message: '密码至少为6位数字', trigger: 'blur' }
        ],
        code: [
          { required: true, message: "请输入6位验证码", trigger: "blur" },
          {
            validator: function(rule, value, callback) {
              if (/^\d{6}$/.test(value) == false) {
                callback(new Error("验证码错误"));
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ]
      },
      url:
        "http://123.57.216.146/wechat_teacher.png"
    };
  },
    watch: {
    '$store.state.isLoggedIn'(newValue) {
      if (newValue && this.$route.query.redirect) {
        this.$router.push(decodeURIComponent(this.$route.query.redirect));
      }
    }
  },
  mounted() {
    // 如果一开始就处于已登录状态，则直接跳转
    if (this.$store.state.isLoggedIn && this.$route.query.redirect) {
      this.$router.push(decodeURIComponent(this.$route.query.redirect));
    }
  },
  methods: {
    async login() {
      const res = await this.$axios.post("/m1/s/login", {
        username: this.username,
        password: this.password
      });
      console.log(res);
      if (res.code === 200) {
         const { token, userId } = res.data;
        // const token = res.data.token; 
        // const userId = res.data.userId;
        // alert(userId)
         this.$store.commit('setToken', token);
         this.$store.commit('setUserID', userId);
        //  登录成功后 登录按钮切换的状态
         this.$store.commit('setLoginStatus', true);

        // 在登录成功后跳转到目标路由
        this.$router.back();
        
        this.$message({
          showClose: true,
          message: "恭喜你，登陆成功",
          type: "success"
        });
      } else {
        this.$message.error('登陆失败！');
      }
    },
    // 注册
    signIn() {
      this.editShow = true;
    },
    // 获取验证码
    async openCode() {
      const res = await this.$axios.post(`/m1/sedSms?phone=${this.ruleForm.phone}`).then(res => {
          if (res.data.code != 200) {
            return this.$message({
              showClose: true,
              message: "验证码发送失败！",
              type: "success"
            });
          } else {
              
              this.$message({
                showClose: true,
                message: "验证码已发送，有效期为2分钟！",
                type: "success"
              });
              this.isSend = false;
              let timer = 60;
              this.sendmsg = timer + "s";
              this.timeFun = setInterval(() => {
                --timer;
                this.sendmsg = timer + "s";
                if (timer == 0) {
                  this.isSend = true;
                  this.sendmsg = "重新发送";
                  clearInterval(this.timeFun);
                }
              }, 1000);
          }
      });
    },
    // 注册-取消
    cancel() {
      this.editShow = false;
    },
    // 注册-立即创建
    async add() {
      this.editShow = false;
       // 构建请求体
      const requestBody = {
        phone: this.ruleForm.phone,
        password: this.ruleForm.checkPass,
        code: this.ruleForm.code
      };
      // 发送POST请求
      const res = await this.$axios.post('/m1/sregister', requestBody)
        .then(res => {
          console.log(res);
          alert("注册成功");
          this.ruleForm.phone = ""
          this.ruleForm.checkPass = ""
          this.ruleForm.code = ""
        })
        .catch(error => {
          console.error('注册失败', error);
        });
        
    },
    // 忘记密码
    unexpected() {
      this.passShow = true;
    }
  }
};
</script>

<style lang='scss' scoped>
* {
  margin: 0;
  padding: 0;
}

.main {
  position: fixed;
  width: 100%;
  height: 100%;
  // background-color: #34495f;
  background: url(https://pwh-img.oss-cn-beijing.aliyuncs.com/%E5%AE%98%E7%BD%91%E9%A1%B5%E9%9D%A2/bg.jpg) no-repeat;
  background-size: 100% 100%;
  .bg {
    width: 350px;
    height: 350px;
    // background-color: white;
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 65%;
    transform: translate(0%, -50%);
    .logo {
      width: 80px;
      height: 80px;
      position: relative;
      left: 50%;
      // top: -50%;
      transform: translate(-50%, -40%);
      border-radius: 50%;
      background-color: white;
      box-shadow: 0px 2px 3px 2px #ccc;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
    .text {
      color: #768797;
      font-size: 26px;
    }
    p {
      margin-bottom: 15px;
      padding: 0 25px;
      input {
        width: 100%;
        border: none;
        box-shadow: 0px 2px 3px 2px #ecedf0;
        border-radius: 16px;
        line-height: 32px;
        padding: 2px 0 2px 25px;
        box-sizing: border-box;
      }
    }
    .btn {
      width: 100%;
      // padding: 0 25px;
      display: flex;
      text-align: right;
      box-sizing: border-box;
      button {
        font-size: 16px;
        width: 100%;
        color: white;
        line-height: 32px;
        border: none;
        border-radius: 16px;
        background: linear-gradient(#2edfd9, #2f9fe6);
      }
      .blue {
        background-color: #08c;
        padding: 0;
      }
    }
    .botbtn {
      span {
        color: #eaebee;
        margin: 3px 0 0 20px;
      }
      button {
        font-size: 14px;
        color: #6dacee;
        background: none;
      }
    }
  }
}

.code {
  width: 60%;
}
::v-deep .is-plain {
  width: 40%;
}
.demonstration {
  display: block;
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
}
::v-deep .el-form-item {
  margin-bottom: 20px;
}
::v-deep .el-button {
  padding: 12px 20px;
}
::v-deep .el-dialog {
  width: 25%;
  border-radius: 10px;
}
::v-deep .el-dialog__wrapper {
  overflow: hidden;
}
</style>
